import { Component, inject } from '@angular/core';
import { LoadingComponent } from '@shared/components';
import { Subscription } from 'rxjs';
import { formatUrl } from '@core/utils';
import { ROUTES } from '@core/constants';
import { HttpService } from '@core/services';

@Component({
  selector: 'app-cp-loading',
  standalone: true,
  imports: [LoadingComponent],
  template: `<app-loading [subscription]="sub"> Content After Loading... </app-loading> `,
  styles: ``,
})
export class CpLoadingComponent {
  sub: Subscription;
  readonly httpService = inject(HttpService);
  constructor() {
    // this is a mock request call function, currently it is responding 200.
    const url = formatUrl(ROUTES.configuration.KYCProviders.retriveKYCDetails, {
      id: '1',
    });
    this.sub = this.httpService.get(url).subscribe();
  }
}
