import { Component } from '@angular/core';
import { CurrencyFormatterPipe } from '@shared/pipes';
import { NgxMaskPipe, provideNgxMask } from 'ngx-mask';

@Component({
  selector: 'app-cp-pipes',
  standalone: true,
  imports: [CurrencyFormatterPipe, NgxMaskPipe],
  providers: [provideNgxMask()],
  template: `
    <h2>CurrencyFormatterPipe</h2>
    <p>{{ 123456789 | currencyFormatter: 'IRR' }}</p>
    <p>{{ 1234567.89 | currencyFormatter: 'USDT' }}</p>
    <p>{{ '987654321' | currencyFormatter }}</p>
    <p>{{ 1234567.89 | mask: 'separator' : { thousandSeparator: ',', suffix: ' IRR' } }}</p>
  `,
  styles: ``,
})
export class CpPipesComponent {}
